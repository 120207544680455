import React, { useContext, useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { storesContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import CardList from '../../components/CardList';
import { CARD_COLORS } from '../../config/constants';
import StudentResourcesStore from './store';
import IconCardContainer from '../../components/Cards/IconCardContainer';
import VideoWidget from '../../components/Video/Widget';
import { KALTURA_CONFIGS } from '../../config/constants';
import './styles.scss';

const StudentResourcesView = () => {
  const { t } = useTranslation();
  const { routerStore } = useContext(storesContext);
  const store = useLocalStore(() => new StudentResourcesStore());
  const resourcesTitle = t('Student Resources');
  const [title, setTitle] = useState(resourcesTitle);

  const getColor = (index) => {
    return CARD_COLORS[index % CARD_COLORS.length];
  };

  const onResourceClick = (item) => {
    if (item.icon === 'video') store.toggleVideoModal();
    else if (item.url) window.open(item.url, '_blank').focus();
    else if (item.subsection)
      routerStore.navigate('studentresources:link-list', {
        section: item.subsection,
      });
  };

  const renderVideo = () => {
    return (
      <React.Fragment>
        {store.shouldVideoShow && (
          <div className="resource-video-wrapper">
            <div className="overlay">
              <div className="overlay-content">
                <VideoWidget
                  isEmbeddedVideo={true}
                  playerId={KALTURA_CONFIGS.videoTutorial.uiConfId}
                  entryId={KALTURA_CONFIGS.videoTutorial.studentTutorialEntryId}
                />
                <div className="text-center">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={() => {
                      store.toggleVideoModal();
                    }}
                  >
                    {t('Close video')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderStudentResourceItems = (items) => {
    return (
      <React.Fragment>
        {items.length > 0 && (
          <CardList>
            {items?.map((i, index) => {
              return (
                <React.Fragment>
                  <IconCardContainer
                    title={i.title}
                    firstLineElement={i.firstLine}
                    secondLineElement={i.secondLine}
                    cardColor={getColor(index)}
                    iconName={i.icon || 'file'}
                    onCardClick={() => {
                      onResourceClick(i);
                    }}
                  />
                </React.Fragment>
              );
            })}
          </CardList>
        )}
      </React.Fragment>
    );
  };

  const renderStudentResources = (sections) => {
    return Object.keys(sections)?.map((s) => {
      return (
        <React.Fragment>
          <section className="m-3">
            <b>{t(sections[s].title)}</b>
            {renderStudentResourceItems(sections[s].items)}
          </section>
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    // Setting the title for the page using the route
    let selectedSubSectionPath = routerStore.route.params.section;

    let selectedSubsectionName = null;
    if (selectedSubSectionPath) {
      selectedSubsectionName = Object.keys(store.subsections)?.filter(
        (subSectionName) => {
          return subSectionName === selectedSubSectionPath;
        },
      );
      if (selectedSubsectionName) {
        const sectionName = store.subsections[selectedSubsectionName].title;
        setTitle(sectionName);

        store.subsection = store.subsections[selectedSubsectionName];
      } else {
        store.subsection = undefined;
        routerStore.navigate('default-redirect');
      }
    } else {
      setTitle(resourcesTitle);
      store.subsection = undefined;
    }
  }, [store, routerStore, resourcesTitle, routerStore.route.params.section]);

  return (
    <div className="resources">
      <h3 className="view-page-title">{title}</h3>
      {!store.subsection ? renderStudentResources(store.sections) : null}
      {!store.subsection && renderVideo()}
      {store.subsection
        ? renderStudentResourceItems(store.subsection.items)
        : null}
    </div>
  );
};

export default observer(StudentResourcesView);
