import { LTI_API_URL , LTI_INFO_API_URL} from '../config/constants';
import { get } from 'axios';

export const getLabLaunchButtonHTML = async (course, labProvider) => {
  try {
    if (!course || !labProvider) {
      throw new Error("course and labProvider are required");
    }
    const courseParam = labProvider === "infosec"  ? course.code : course.slug;

    const response = await get(
      `${LTI_API_URL}?course_code=${courseParam}&lti_provider=${labProvider}`,
    );
    const htmlResponse = response.data;
    return htmlResponse
    
  } catch (error) {
    throw new Error(error);
  }
};

export const getLabProviderDetails = async () => {
  try {
    
    const response = await get(
      `${LTI_INFO_API_URL}`,
    );
    
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
