import React, { Component } from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withTranslation } from 'react-i18next';
import Store from './store';
import SVG from 'react-inlinesvg';
import sortNeutralIcon from '../../../../../img/icons/sort_neutral.svg';
import './styles.scss';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

@inject('routerStore')
@withTranslation()
@observer
class ReSequencing extends Component {
  static propTypes = {
    routerStore: PropTypes.object.isRequired,
    data: PropTypes.string.isRequired,
    quizAttributeId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    chapter: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const {
      routerStore: { route },
      chapter,
    } = this.props;
    const slug = route.params.course;
    Store.interactiveInfoFetch(slug, chapter);
  }

  // set items value on store on drag end
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      Store.items,
      result.source.index,
      result.destination.index,
    );
    Store.setItem = items;
  };

  // reset correct incorrect value on drag start
  onDragStart = () => {
    document.querySelectorAll('.step').forEach((elem) => {
      elem.classList.remove('correct');
      elem.classList.remove('incorrect');
    });
  };

  // handle check event
  handleCheck = () => {
    const { quizAttributeId } = this.props;
    const { reSequencingData, originalArray, items } = Store;
    let answer = reSequencingData[quizAttributeId].answer.split(',');
    answer = answer.map((i) => parseInt(i, 10));
    const sorted = answer.map((o, index) => [o, originalArray[index]]).sort();
    const correctOrder = sorted.map((x) => x[1]);
    const getListItems = document.querySelectorAll('.step');
    const itemsArray = [];
    items.forEach((item) => {
      itemsArray.push(item.id);
    });
    correctOrder.forEach((item, i) => {
      if (item === itemsArray[i]) {
        getListItems[i].classList.add('correct');
      } else {
        getListItems[i].classList.add('incorrect');
      }
    });
  };

  // reset quiz
  handleReset = () => {
    const { originalItems } = Store;
    Store.setItem = originalItems;
    document.querySelectorAll('.step').forEach((elem) => {
      elem.classList.remove('correct');
      elem.classList.remove('incorrect');
    });
  };

  // show solution
  handleSolution = () => {
    const { quizAttributeId } = this.props;
    const { reSequencingData, originalItems } = Store;
    let answer = reSequencingData[quizAttributeId].answer.split(',');
    answer = answer.map((i) => parseInt(i, 10));
    const sorted = answer.map((o, index) => [o, originalItems[index]]).sort();
    const correctOrder = sorted.map((x) => x[1]);
    Store.setItem = correctOrder;
    document.querySelectorAll('.step').forEach((elem) => {
      elem.classList.remove('incorrect');
      elem.classList.add('correct');
    });
  };

  createLabels = (accumulator, nodes) => {
    nodes.forEach((node) => {
      const { children } = node;
      if (children) {
        this.createLabels(accumulator, children);
      } else {
        accumulator.push(node.data);
      }
    });
    return accumulator;
  };

  render() {
    const { data, t } = this.props;
    const { items } = Store;
    const htmlParser = new DOMParser();
    const node = htmlParser.parseFromString(data, 'text/html');
    const html = node.body.innerHTML;
    let index = 0;
    const parserOption = {
      // eslint-disable-next-line consistent-return
      replace: ({ attribs, children }) => {
        if (attribs && attribs.class === 'step') {
          const labels = this.createLabels([], children);
          const label = labels.join('');
          index += 1;
          Store.storeMatchedLabel(label, index);
          return <div />;
        }
      },
    };
    return (
      <React.Fragment>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <ul ref={provided.innerRef} className="resequencing">
                {items.map((item, itemIndex) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={itemIndex}
                  >
                    {(providedDraggable) => (
                      <li
                        className="step"
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        <div className="resequencing_label">
                          <SVG src={sortNeutralIcon} />
                          {item.content}
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div className="interactive_buttons">
          <Button variant="success" type="submit" onClick={this.handleCheck}>
            {t('Check')}
          </Button>
          <Button variant="success" onClick={this.handleReset}>
            {t('Reset')}
          </Button>
          <Button variant="success" onClick={this.handleSolution}>
            {t('Show Solution')}
          </Button>
        </div>
        {Parser(html, parserOption)}
      </React.Fragment>
    );
  }
}

export default ReSequencing;
