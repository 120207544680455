const studentResourceSubSections = {
    openshift: {
        title: 'Student Resources - Openshift',

        items: [
            {
                title: 'Learn about containers and kubernetes with Red Hat Academy',
                url: 'https://www.kaltura.com/index.php/extwidget/preview/partner_id/2032581/uiconf_id/47857973/entry_id/1_h54ekilg/embed/dynamic?',
                icon: 'file',
            },
            {
                title: 'Infographic: The Benefits of Training on Red Hat Openshift',
                url: 'https://pnt.redhat.com/pnt/p-18455135/IDC-Infograph...OpenShift.pdf',
                icon: 'file',
            },
            {
                title: 'Infographic: OpenShift Skills Path',
                url: 'https://pnt.redhat.com/pnt/p-10184775/openshiftskillspath.pdf',
                icon: 'file',
            },
            {
                title:
                    'Datasheet: Red Hat OpenShift I: Containers & Kubernetes (DO180)',
                url: 'https://pnt.redhat.com/pnt/p-10139965/DO180Datasheet2.pdf',
                icon: 'file',
            },
            {
                title: 'E-Book: Container storage for dummies',
                url: 'https://www.redhat.com/en/resources/container-storage-dummies',
                icon: 'file',
            },

            {
                title:
                    'E-Book: Top considerations for cloud-native databases and data analytics',
                url: 'https://www.redhat.com/rhdc/managed-files/cl-openshift-for-databases-e-book-f27425-20210210-v2.pdf',
                icon: 'file',
            },
        ],
    },
};

const studentResourceSections = {
    // Matching the folder name in S3 and the section route
    Communites: {
        title: 'Communities',
        items: [
            {
                title: 'Red Hat Academy Talent Network',
                url: 'https://rhtapps.redhat.com/academy-talent',

                icon: 'file',
                firstLine: 'Connect with recruiters and find job opportunities',
            },
            {
                title: 'Red Hat Learning Community',
                url: 'https://learn.redhat.com/t5/Red-Hat-Academy/gh-p/RedHatAcademy',
                icon: 'file',
                firstLine:
                    'Join discussions in our community dedicated to learning Red Hat',
            },
        ],
    },
    PlatformInformation: {
        title: 'Platform Information',
        items: [
            {
                title:
                    'Red Hat Academy Learning Platform Tutorial - Student Overview',
                url: 'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52134902?iframeembed=true&entry_id=1_i1m1490c',
                icon: 'video',
                firstLine: 'Watch the student overview',
            },
        ],
    },
    Certification: {
        title: 'Red Hat Certification',
        items: [
            {
                title: 'Infographic: Why become Red Hat certified?',
                url: 'https://www.redhat.com/en/resources/why-become-certified-idc-report-infographic',
                icon: 'file',
                firstLine: 'Read the Infographic',
            },
            {
                title: 'Brief: Ways to Test',
                url: 'https://www.redhat.com/cms/managed-files/tr-ways-to-test-certification-brief-f24152pr-202006-en.pdf',
                icon: 'file',
                firstLine: 'Read the Brief',
            },
        ],
    },

    AdditionalResources: {
        title: 'Additional Resources',
        items: [
            {
                title: 'Red Hat Jobs | Opportunities are Open',
                url: 'https://www.redhat.com/en/jobs',
                icon: 'file',
                firstLine: 'Find jobs and learn about life at Red Hat',
            },
            {
                title: 'Red Hat Developer Subscription',
                url: 'https://developers.redhat.com/articles/getting-red-hat-developer-subscription-what-rhel-users-need-know',
                icon: 'file',
                firstLine: 'Discover tools for problem solvers who create careers with code',
            },
            {
                title: 'Openshift',
                url: '',
                icon: 'file',
                subsection: 'openshift',
                firstLine: 'Explore OpenShift, Containers and Kubernetes',
            },
        ],
    },
};

export {studentResourceSubSections, studentResourceSections};