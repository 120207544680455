import React, { Component } from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Store from './store';
import './styles.scss';

@inject('routerStore')
@withTranslation()
@observer
class Matching extends Component {
  static propTypes = {
    routerStore: PropTypes.object.isRequired,
    chapter: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.store = new Store();
  }

  componentDidMount() {
    const { routerStore, chapter } = this.props;
    const { route } = routerStore;
    this.store.setLabelData(route.params.course, chapter);
  }

  allowDrop = (ev) => {
    if (!ev.target.draggable) {
      ev.preventDefault();
    }

    return false;
  };

  drag = (ev, label) => {
    ev.dataTransfer.setData('label', label);
    this.store.setDragItem.dragged = ev.currentTarget;
  };

  updateDraggable = (visibility, droppedOn, draggable) => {
    draggable.displayClassName = visibility;
    draggable.droppedOn = droppedOn;
  };

  drop = (ev) => {
    ev.preventDefault();
    const label = this.store.setDragItem.dragged.id;
    let elem = this.store.setDragItem.dragged;
    // replace the target area with the new element that is being dropped
    const hasDroppedElem = this.store.draggablesArray.find(
      (d) => d.droppedOn === ev.target.className,
    );
    if (hasDroppedElem) {
      ev.target.querySelectorAll('*').forEach((n) => n.remove());
      this.updateDraggable('show', '', hasDroppedElem);
    }
    // if element is dropped on an empty target area,
    // clone the dragged element before appending
    // also bind the draggable event to make the cloned elment draggable again.
    // Note: CloneNode doesnt clone events of the element.
    if (this.store.setDragItem.dragged.parentElement.tagName !== 'TD') {
      elem = elem.cloneNode(true);
      elem.addEventListener('dragstart', (evt) => this.drag(evt, label));
    }
    ev.target.appendChild(elem);
    this.store.setDragItem.draggedItems.push(ev.target);

    // find the dragged element and set the visibility to none.
    const draggedItemIndex = this.store.draggablesArray.findIndex(
      (d) => d.label === label,
    );
    if (draggedItemIndex >= 0) {
      this.store.draggablesArray[draggedItemIndex].displayClassName = 'hide';
      this.store.draggablesArray[draggedItemIndex].droppedOn =
        ev.target.className;
    }
  };

  handleReset = () => {
    const { routerStore, chapter } = this.props;
    const { route } = routerStore;
    this.store.showSolution = false;
    this.store.resetQuizData(route.params.course, chapter);
    this.updateDraggables('show');
  };

  handleSolution = () => {
    this.store.removeItem();
    this.store.matchedArray = [];
    this.store.showSolution = true;
  };

  handleCheck = () => {
    this.store.showSolution = false;
    this.store.checkData();
  };

  updateDraggables = (visibility) => {
    const newDraggables = this.store.draggablesArray.map((draggable) => {
      this.updateDraggable(visibility, '', draggable);
      return draggable;
    });
    this.store.setdraggablesArray = newDraggables;
  };

  render() {
    const { data, t } = this.props;
    const htmlParser = new DOMParser();
    const node = htmlParser.parseFromString(data, 'text/html');
    const html = node.body.innerHTML;

    const parserOption = {
      replace: ({ attribs, children }) => {
        if (attribs && attribs.class === 'solution') {
          const label = children?.[0]?.data?.trim();
          if (label) {
            this.store.storeMatchedLabel(label);
          }
          return (
            <td
              className={label}
              onDrop={(e) => this.drop(e)}
              onDragOver={(e) => this.allowDrop(e)}
            >
              {this.store.showSolution && (
                <div className="roc_matching_label correct">{label}</div>
              )}
            </td>
          );
        }

        return '';
      },
    };
    Parser(html, parserOption);
    return (
      <div className="matching">
        <div className="matching-pool clearfix">
          {this.store.draggablesArray.length
            ? this.store.draggablesArray.map((d) => (
                <div
                  className="boxable_component"
                  style={{ display: 'inline-block' }}
                  key={d.label}
                >
                  <div
                    className={`roc_matching_label ${d.displayClassName}`}
                    draggable="true"
                    onDragStart={(e) => this.drag(e, d.label)}
                    id={d.label}
                  >
                    {d.label}
                  </div>
                </div>
              ))
            : ''}
        </div>
        {Parser(html, parserOption)}

        <div className="interactive_buttons">
          <Button variant="success" type="submit" onClick={this.handleCheck}>
            {t('Check')}
          </Button>
          <Button variant="success" onClick={this.handleReset}>
            {t('Reset')}
          </Button>
          <Button variant="success" onClick={this.handleSolution}>
            {t('Show Solution')}
          </Button>
        </div>
      </div>
    );
  }
}

export default Matching;
