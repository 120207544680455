import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-mobx-router5';
import SVG from 'react-inlinesvg';
import leftArrowIcon from '../../../img/icons/left_arrow_white.svg';
import rightArrowIcon from '../../../img/icons/right_arrow_white.svg';
import { storesContext } from '../../../stores';


const Navigation = (props) => {
  const { t } = useTranslation();
  const { routerStore, courseStore } = useContext(storesContext);

  const { route } = routerStore;

  const { next, previous, scroll } = props;

  const Button = (direction, link) => (
    <Link
      href
      routeName={courseStore.coursePageRouteName}
      routeParams={{
        course: route.params.course,
        page: link,
        ...(courseStore.coursePageRouteParamsByRole),
      }}
      {...(scroll && {
        onClick: () =>
          window.scrollTo({
            top: 0,
          }),
      })}
      className={`btn btn-sm btn-primary ${
        direction === 'next' ? 'next-btn' : 'prev-btn'
      }`}
    >
      {direction === 'next' ? (
        <SVG src={rightArrowIcon} />
      ) : (
        <SVG src={leftArrowIcon} />
      )}
      {`${direction === 'next' ? ' ' + t('Next') : ' ' + t('Previous')}`}
    </Link>
  );

  return (
    <div className="navigation">
      {previous && Button('previous', previous)}
      {next && Button('next', next)}
    </div>
  );
};

Navigation.propTypes = {
  next: PropTypes.string,
  previous: PropTypes.string,
  scroll: PropTypes.bool,
};

Navigation.defaultProps = {
  next: null,
  previous: null,
  scroll: false,
};

export default observer(Navigation);
