import React , { Suspense } from "react";

import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import rootStore from "./stores";
import './styles/base.scss';
import Layout from "./components/Layout";
import configureRouter from "./config/router5";
import { setAxiosRequestHeaders } from './services/NetworkingService';
import configureI18n from './config/i18n';

configureI18n(rootStore.uiStore);
const router = configureRouter(
  process.env.NODE_ENV === "development",
  rootStore
);
setAxiosRequestHeaders();
router.start(() => {
  ReactDOM.render(
    <Provider {...rootStore}>
      <Suspense fallback="">
      <Layout />
      </Suspense>
    </Provider>,
    document.getElementById("root")
  );
});
