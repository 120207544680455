import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { storesContext } from '../../stores';
import GlobeIcon from '../../img/icons/globe.svg';
import { Nav, Navbar, Dropdown, NavLink } from 'react-bootstrap';
import UserIcon from '../../img/icons/user.svg';
import SVG from 'react-inlinesvg';
import { observer, useLocalStore } from 'mobx-react';
import Profile from './Profile';
import GenericAlert from '../Alert/GenericAlert';
import HeaderStore, { headerContext } from './store';
import { REDHAT_COM_URL, RHA_FAQ_URL } from '../../config/constants';
import './styles.scss';
const Header = () => {
  const { routerStore, uiStore, userStore, alertStore } = useContext(
    storesContext,
  );
  const { isLoggedIn, user } = userStore;
  const { i18n, t } = useTranslation();
  const { languages } = uiStore;
  const language = languages.find((lang) => lang.token === i18n.language) || {};
  const headerStore = useLocalStore(() => new HeaderStore());

  let { display_name: languageName } = language;
  const globalAlert = alertStore.currentAlerts?.filter(
    (alert) => alert.enabled && alert.environment.includes('global'),
  );
  
  
  return (
    <React.Fragment>
      <header className="topbar-menu pb-0">
        <Navbar variant="dark" className="container-fluid">
          <Nav>
            <Nav.Link href={REDHAT_COM_URL} target="_blank">
              {t('Redhat.com')}
            </Nav.Link>
            <Nav.Link onClick={() => routerStore.navigate('help')}>
              {t('Support')}
            </Nav.Link>
            <Nav.Link href={RHA_FAQ_URL} target="_blank">
              {t('FAQ')}
            </Nav.Link>
          </Nav>
          {isLoggedIn && globalAlert.length > 0 && (
            
            <Nav className="m-auto global-alert">
              <Nav.Link>
               
                <GenericAlert type={globalAlert[0].priority} text={globalAlert[0].description} />
              </Nav.Link>
            </Nav>
          )}
          <Nav className="ml-auto">
            <Dropdown alignRight className={isLoggedIn && 'language-selector'}>
              <Dropdown.Toggle id="language-switcher" as={NavLink}>
                <SVG src={GlobeIcon}>{t('Language')}</SVG>
                <span className="language-name">{languageName}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {languages.map((lang) => {
                  const { display_name: name } = lang;
                  return lang.token ? (
                    <Dropdown.Item
                      onClick={() => i18n.changeLanguage(lang.token)}
                      key={lang.token}
                      eventKey={lang.token}
                    >
                      {t(name)}
                    </Dropdown.Item>
                  ) : null;
                })}
              </Dropdown.Menu>
            </Dropdown>

            {isLoggedIn && (
              <React.Fragment>
                <div
                  className="profile-icon-container"
                  onClick={() => headerStore.toggleProfile()}
                >
                  <SVG src={UserIcon} className="user-icon">
                    {t('Profile')}
                  </SVG>
                  <span className="language-name">{user.username}</span>
                </div>
              </React.Fragment>
            )}
          </Nav>
        </Navbar>
      </header>
      <headerContext.Provider value={headerStore}>
        <Profile />
      </headerContext.Provider>
    </React.Fragment>
  );
};

export default observer(Header);
