import { studentResourceSubSections } from './studentResources';

// API endpoints
export const BASE0 = `/${window.location.pathname.split('/')[1]}`;
export const BASE = `${BASE0}`;
export const APP_BASE = `${BASE}/app`;
export const API_PREFIX = 'api';
export const SAPI_PREFIX = 'sapi';
export const RHZ_BASE = `${BASE}/rhz/`;
export const RHZ_API_BASE = `${RHZ_BASE}api/`;
export const OPS_BASE = `${BASE}/ops/`;
export const OPS_API_BASE = `${OPS_BASE}api/`;
export const VTC_BASE = `${BASE}/vtc/`;

export const RHA_SUPPORT_LINK = 'mailto:tecsupport-rha@redhat.com';
export const API_BASE = `${BASE}/${API_PREFIX}/`;
export const SAPI_BASE = `${BASE}/${SAPI_PREFIX}/rest/`;

export const API_LOGIN = `${API_BASE}login/`;
export const API_LOGOUT = `${API_BASE}logout/`;
export const USER_RECORD_API = `${SAPI_BASE}users/`;
export const CURRENT_USER_RECORD_API = `${USER_RECORD_API}me`;
export const SSO_LOGIN_API = `${BASE}/auth_idp/@@login/`;
export const SERVER_CONFIG_API = `${SAPI_BASE}configuration`;
export const REDHAT_COM_URL = 'http://www.redhat.com';
export const RHA_FAQ_URL = 'https://www.redhat.com/en/services/red-hat-academy-faq';
export const OPS_DJANGO_ADMIN_VIEW = `${BASE}/admin/`;
export const VOCABULARY_API = `${SAPI_BASE}vocabulary`;
export const VOCABULARY_COUNTRY_API = `${VOCABULARY_API}/countries@@<lang>`;
export const CATALOG_ENTRY_API = `${SAPI_BASE}catalog_entry/catalog`;
export const PROGRESS_API_URL = `${SAPI_BASE}progress`;
export const COURSE_PROGRESS = `${RHZ_API_BASE}progress`;
export const COURSE_COLLATERAL_API = `${SAPI_BASE}course_collateral/`;
export const EXAM_COLLATERAL_API_URL = `${SAPI_BASE}exam_collateral/`;
export const CLASS_API_URL = `${SAPI_BASE}user_class`;
export const ACADEMY_CLASS_API_URL = `${CLASS_API_URL}?academy_id=<academy_id>`;
export const ACADEMY_CLASS_CSV_API_URL = `${ACADEMY_CLASS_API_URL}&report_type=<report_type>`;
export const CLASS_ATTACHED_FILE_API = `${CLASS_API_URL}/<class_id>/storage/`;
export const CLASS_STUDENTS_API_URL = `${CLASS_API_URL}/<class_id>/class_dashboard/`;
export const CLASS_PATCH_API_URL = `${CLASS_API_URL}/<class_id>/`;
export const ACADEMY_API_URL = `${SAPI_BASE}academy/`;
export const USER_DATA_API_URL = `${SAPI_BASE}user_data/`;
export const INTERACTIVE_VIEW_BASE = `${BASE}/es/api`;
export const INTERACTIVE_API = `${INTERACTIVE_VIEW_BASE}/interactive/<course-slug>/<chapter-name>`;
export const JWPLAYER_CLOUD_URL = 'https://cdn.jwplayer.com/players/';
export const CARD_COLORS = [
  '#b8bdbf',
  '#7080d4',
  '#7ac4e5',
  '#00bf89',
  '#ff433e',
  '#fb8049',
  '#f3b21c',
  '#f36f8f',
  '#945ecf',
  '#61b426',
];
export const MAIL_FORMAT = /^([A-Za-z0-9]+[.-_])*[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,})+$/;
export const COURSE_INFO_API = `${RHZ_API_BASE}course_info/<course-slug>`;
export const CERTIFICATE_OF_ATTENDANCE_BASE_API = `${API_BASE}certificates/attendance`;
export const CERTIFICATE_OF_ATTENDANCE_UUID_API = `${API_BASE}certificates/attendance/uuid/`;
export const TRAINING_CONTACTS =
  'https://www.redhat.com/en/services/training/global-contacts';
export const RHLC_BASE =
  BASE0.indexOf('rha-uat') > 0
    ? 'https://learn-stage.redhat.com/'
    : 'https://learn.redhat.com/';
export const CERTIFICATE_OF_ATTENDANCE_RHLC_URL = `${RHLC_BASE}t5/custom/page/page-id/rha-certificate-of-attendance`;
export const COURSE_STRUCTURE_API = `${API_BASE}courses/<course-slug>/structure`;
export const COURSE_PAGE = `${API_BASE}courses/<course-slug>/pages/<page>`;
export const KALTURA_SESSION_API = `${BASE}/${SAPI_PREFIX}/kaltura_session/`;
export const LEARNING_PATH_API = `${SAPI_BASE}learning_path`;
export const VC_PLAYER = `${VTC_BASE}rvideo_class_player`;
export const ALERTS_API_URL = `${SAPI_BASE}alerts/`;
export const PROMOCODE_API = `${SAPI_BASE}lms_promo`;
export const ACADEMY_API = `${SAPI_BASE}academy`;
export const ACADEMY_PATCH_API = `${ACADEMY_API}/<academy-id>`;
export const ACADEMY_STATUS_API = `${ACADEMY_PATCH_API}/update_status`;
export const ACADEMY_INSTRUCTOR_LIST_API = `${ACADEMY_API}/<academy-id>/instructor`;
export const ACADEMY_INSTRUCTOR_PROMO_ELIGIBILITY_API = `${ACADEMY_API}/<academy-id>/instructor_promo_eligibility`;
export const ACADEMY_STUDENT_SELF_PACED_VIDEO_ACCESS_API = `${ACADEMY_API}/<academy-id>/student_self_paced_video_access?offering_slug=<offering-slug>`;
export const ACADEMY_INSTRUCTOR_CSV_API = `${ACADEMY_INSTRUCTOR_LIST_API}?report_type=<report_type>`;
export const ACADEMY_STUDENT_LIST_API = `${ACADEMY_API}/<academy-id>/students`;
export const ACADEMY_STUDENT_ACTIVITY_API = `${ACADEMY_PATCH_API}/student_activity?student=<username>`;
export const ACADEMY_STUDENT_LIST_API_CSV = `${ACADEMY_STUDENT_LIST_API}?report_type=<report_type>`;
export const LTI_API_URL = `${SAPI_BASE}lti`;
export const LTI_INFO_API_URL = `${SAPI_BASE}lti_lab_info`;
export const ADOPT_ACADEMY_API = `${ACADEMY_API}/<academy-id>/adopt_academy`;
export const RESOURCES_API_URL = `${ACADEMY_API}/common_store`;
export const CLASS_ASSIGNMENT_URL = `${SAPI_BASE}assignment`;
export const CLASS_ASSIGNMENT_DETAIL_URL = `${SAPI_BASE}assignment/<assignment-id>`;
export const CLASS_ASSIGNMENT_ACADEMY_URL = `${CLASS_ASSIGNMENT_URL}?academy_id=<academy-id>`;
export const QUESTION_BANK_URL = `${SAPI_BASE}question_bank/?course_slug=<course-slug>`;
export const CLASS_AS_STUDENT_ASSIGNMENT_URL = `${CLASS_API_URL}/<class-id>/assignment`;
export const CLASS_DETAIL_BY_ID_API_URL = `${CLASS_API_URL}/<class-id>`;
export const CONSUMPTION_SUMMARY = `${SAPI_BASE}academy/consumption_summary`;
export const CARD_BORDER_COLOR = '#50A3B0';
export const ROLES = {
  student: { name: 'student', defaultRoute: 'summary' },
  instructor: { name: 'instructor', defaultRoute: 'classes' },
  academy_admin: { name: 'academy_admin', defaultRoute: 'classes' },
  bdm: { name: 'regional_bdm', defaultRoute: 'academysummary' },
  global: { name: 'global_bdm', defaultRoute: 'academysummary' },
};

export const FEEDBACK_TYPES = [
  { value: '', label: 'Select type' },
  { value: 'defect', label: 'Defect' },
  { value: 'enhancement', label: 'Enhancement' },
  { value: 'general', label: 'General' },
];
export const VERSION = 'Version 1.6.3';

export const PERCENTAGES = [
  { value: 0.25, label: '25%' },
  { value: 0.5, label: '50%' },
  { value: 0.75, label: '75%' },
  { value: 1, label: '100%' },
];
export const ASSIGNMENT_TYPES = {
  quiz: { name: 'Quiz', icon: 'quiz', question_type: 'quiz' },
  knowledgeCheck: {
    name: 'knowledgeCheck',
    icon: 'code-quiz',
    question_type: 'code_challenge',
  },
};
export const DEFAULT_LANGUAGE = 'en-US';
export const CLASS_STUDENT_PROMOCODE_API = `${CLASS_API_URL}/<class-id>/partner_class_promo`;
export const PRIVACY_POLICY_URL =
  'https://www.redhat.com/footer/privacy-policy.html';
export const TRAINING_POLICIES_URL =
  'https://www.redhat.com/en/about/red-hat-training-policies';
export const TERMS_OF_USE_URL = 'https://www.redhat.com/en/about/terms-use';
export const ALL_POLICIES_URL =
  'https://www.redhat.com/en/about/all-policies-guidelines';
export const FEEDBACK_API_URL = `${ACADEMY_API}/feedback`;
export const RAVELLO_LAB = `${RHZ_API_BASE}ravello_lab`;
export const LAB_DEFINITION_API = `${SAPI_BASE}lab_definition2/`;
export const USER_LAB_API = `${SAPI_BASE}user_lab2/`;

export const REFER_AN_ACADEMY_API = `${ACADEMY_PATCH_API}/refer_academy`;
export const INSTRUCTOR_DETAILS = `${CLASS_API_URL}/<class_id>/instructor_info`;
export const COURSE_CERTS_API_URL = `${SAPI_BASE}rha_course_certification`;
export const MAX_FILE_SIZE_BYTES = 104857600;
export const INHIBIT_LAB_START_API = `${SAPI_BASE}inhibit_lab_start/`;
export const LAB_AUTOSHUTDOWN_POLLING_SECONDS = 60;
export const LAB_DATA_POLLING_SECONDS = 15;
export const USER_SUBSCRIPTION_API = `${USER_RECORD_API}<uuid>/current_subscription`;
export const QUOTAS_API = `${SAPI_BASE}quotas`;
export const ALLOWED_ADDITIONAL_LABS_ROL_COURSE = ['rh124', 'rh134'];
export const INSTRUCTOR_COURSE_COMPLETION_ATTENDANCE_PERCENTAGE = 75;
export const LAB_IMAGES_API_URL = `${SAPI_BASE}lab_store`;
export const LAB_STORE_IMAGES_API_URL = `https://rhalabstore.ole.redhat.com/`;
export const CLOUDFRONT_SIGNED_API = `${BASE}/${SAPI_PREFIX}/signed_resource/`;
export const RESOURCES = {
  Student_Resources: [
    {
      title: 'Red Hat Academy Learning Platform - Student Tutorial',
      url: 'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52134902?iframeembed=true&entry_id=1_i1m1490c',
    },
    {
      title: 'Red Hat Developer Subscription',
      url: 'https://developers.redhat.com/articles/getting-red-hat-developer-subscription-what-rhel-users-need-know',
    },
  ],
  Openshift: studentResourceSubSections.openshift.items,
};
export const DATE_RANGE_DEFAULT = 3;
export const DATE_RANGE_PICKER_MONTH_LIMIT = 60;
export const REGION_MAP = `${SAPI_BASE}config?config_name=<config_name>`;
export const COMPONENT_PERMISSIONS = {
  AdoptAcademy: {
    roles: [ROLES.academy_admin.name],
  },
  EditClass: {
    roles: [ROLES.academy_admin.name, ROLES.instructor.name],
  },
  ClassesCsvDownload: {
    roles: [ROLES.bdm.name, ROLES.global.name],
  },
  StudentsCsvDownload: {
    roles: [ROLES.bdm.name, ROLES.global.name],
  },
  InstructorsCsvDownload: {
    roles: [ROLES.bdm.name, ROLES.global.name],
  },
  RegionsAcademyFilter: {
    roles: [ROLES.global.name],
  },
  EnableDisableAcademy: {
    roles: [ROLES.global.name],
  },
};
export const REPORT_SEARCH = `${OPS_API_BASE}reports/search/`;
export const REPORT_MANAGE = `${REPORT_SEARCH}manage`;
export const JOBS = `${OPS_API_BASE}jobs`;
export const ASYNC_JOBS_INTERVAL = 5;

export const SESSION_STORAGE_KEYS = {
  ACADEMY_FILTER: 'AcademyFilter',
  VIDEO_CONSUMPTION_FILTER: 'VideoConsumptionFilter',
};
export const ANNOUNCEMENT = `${SAPI_BASE}announcement/`;
export const ANNOUNCEMENT_GET_API = `${ANNOUNCEMENT}all_announcements`;
export const ANNOUNCEMENT_UPDATE_API = `${ANNOUNCEMENT}<doc_id>`;
export const ANNOUNCEMENT_DELETE_API = `${ANNOUNCEMENT}<doc_id>`;
export const ACADEMIES = `${SAPI_BASE}academy`;
export const COUNTRIES = `${SAPI_BASE}countries`;

export const REPORT_TYPES = {
  consumption: {
    type: 'consumption',
    label: 'Academy Summary',
    command: 'global-bdm-report-csv',
  },
  user_progress: {
    type: 'user_progress',
    label: 'User Progress',
    command: 'user-progress-report-csv',
  },
  user_lab_consumption: {
    type: 'user_lab_consumption',
    label: 'User Lab Consumption',
    command: 'rha-pilot-metric-report-csv',
  },
  academy_lab_consumption: {
    type: 'academy_lab_consumption',
    label: 'Academy Lab Consumption',
    command: 'rha-pilot-class-consumption-report-csv',
  },
  kaltura_video_consumption_report: {
    type: 'kaltura_video_consumption_report',
    label: 'Video Consumption',
    command: 'rha-video-consumption-report-csv',
    requiredParam: ['start_date','end_date','is_video_selected'],
  },
};

export const REPORT_COLUMNS = {
  REPORT_TITLE: 'report_title',
  GENERATED_DATE: 'generatedDate',
};

export const TYPE_OPTIONS = [
  { value: 'info', label: 'Info' },
  { value: 'danger', label: 'Alert' },
];

export const REGION_OPTIONS = [
  { value: 'apac', label: 'APAC' },
  { value: 'emea', label: 'EMEA' },
  { value: 'latam', label: 'LATAM' },
  { value: 'north_america', label: 'North America' },
];

export const AUDIENCE_OPTIONS = [
  { value: 'global_bdm', label: 'Global BDM' },
  { value: 'regional_bdm', label: 'Regional BDM' },
  { value: 'academy_admin', label: 'Academy Admin' },
  { value: 'instructor', label: 'Instructor' },
  { value: 'student', label: 'Student' },
];
export const INITIAL_YEAR = 20;
export const DISPLAY_ANNOUNCEMENT_INTERVAL = 5000;
export const NO_CLASS = 'noclass';
export const EXAM_DEFAULT_VERSION = '1.0';

export const ZDOC_BASE_API = `${SAPI_BASE}zdoc/<zdoc-name>/@@query`;
export const ZDOC_POST_QUERY_API = `${ZDOC_BASE_API}?size=<size>&from=<from>`;
export const ZDOC_COURSE_INFO = `course_info`;

export const KALTURA_CONFIGS = {
  partnerId: 2032581,
  player: {
    uiConfId: 49478072,
  },
  courseContentInlinePlayer: {
    uiConfId: 52286042,
  },
  videoTutorial: {
    uiConfId: 52134902,
    labsTutorialEntryId: '1_40t6msv3',
    admininstructorTutorialEntryId: '1_75qscctp',
    studentTutorialEntryId: '1_i1m1490c',
    promoVideoEntryId: '1_lere045r',
  },
};

export const SUPPORT_API_URL = `${BASE}/${SAPI_PREFIX}/support_case/`;
export const DOMAIN = `${window.location.host}`;

